import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Logo } from './Logo';
import { MenuButtons } from './MenuButtons';
import { MenuItem } from './MenuItem';
import { Wrap } from './Wrap';
import { UserService } from "../services/user/user.service";
import { ActiveBranchService } from "../services/active-branch/active-branch.service";
import { FormsModule } from "@angular/forms";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'Menu',
  imports: [FormsModule, Logo, MenuButtons, MenuItem, Wrap],
  template: `
    <Wrap
        [class]="
        'flex !max-w-[1600px] min-h-[82.5px] justify-between items-center py-4 z-40 relative'
      "
    >
      <a href="/" class="mr-auto" aria-label="Combidesk">
        <Logo></Logo>
      </a>

      @if (activeBranches) {
        <div>
          <select
              class="appearance-none text-xs text-center text-gray-800 rounded mr-6 px-3 py-1.5 w-30 overflow-ellipsis opacity-60"
              [(ngModel)]="activeBranch"
              (change)="activateBranch()"
          >
            <option [value]="activeBranch">{{ activeBranch }}</option>
            @for (branch of activeBranches; track branch) {
              <option [value]="branch">
                {{ branch }}
              </option>
            }
          </select>
          <div class="text-center text-xs text-white">{{ getUpdateStatus() }}</div>
        </div>
      }

      <div class="flex flex-col items-center w-full p-5 pt-20 absolute left-0 top-0 z-30
          {{
          menuOpen
            ? 'opacity-100 transition-opacity duration-200'
            : 'opacity-0 pointer-events-none'
        }}
          lg:flex-row lg:w-auto lg:p-0 lg:relative lg:text-base
          lg:pointer-events-auto lg:opacity-100
          {{ loggedIn ? 'lg:ml-auto lg:mr-4' : '' }}
        ">
        @for (item of menuItems; track item.label) {
          <MenuItem [item]="item"></MenuItem>
        }

        <MenuButtons [menuOpen]="menuOpen" [class]="'flex lg:hidden mt-10'"></MenuButtons>
      </div>

      <div
        class="
        bg-purple-600 h-screen w-screen fixed top-0 left-0 z-20
        lg:pointer-events-none lg:opacity-0
        {{
          menuOpen
            ? 'opacity-100 transition-opacity duration-200'
            : 'opacity-0 pointer-events-none'
        }}
        "
      ></div>

      <MenuButtons [menuOpen]="menuOpen" [class]="'hidden md:flex mr-4 lg:mr-0'"></MenuButtons>

      <!-- hamburger menu -->
      <div
        class="
            lg:hidden w-12 h-12 center cursor-pointer -mr-2 md:-mr-4 z-40
          "
        (click)="toggleMenu()"
      >
        <div
          class="
          h-0.5 w-5 bg-white absolute transition-transform duration-100
          {{ menuOpen ? 'rotate-45' : '-translate-y-[7px]' }}
          "
        ></div>
        <div
          class="
          h-0.5 w-5 bg-white absolute transition-transform duration-100
          {{ menuOpen ? 'scale-x-0' : '' }}
         "
        ></div>
        <div
          class="
          h-0.5 w-5 bg-white absolute transition-transform duration-100
          {{ menuOpen ? '-rotate-45' : 'translate-y-[7px]' }}
          "
        ></div>
      </div>
    </Wrap>
  `,
})
export class Menu implements OnInit, AfterViewInit {
  menuItems: {
    href: string;
    label: string;
    icon?: string;
    children?: { label: string; href: string }[];
  }[];

  env = environment.env;

  menuOpen = false;
  loggedIn: boolean;

  activeBranch = "master"; // may be overridden
  activeBranches: Array<string>;

  private requestedBranch: string;

  constructor(private userService: UserService,
              private activeBranchService: ActiveBranchService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loggedIn = this.userService.getUser()?.loggedIn;

    // on demo, we can switch git branches by the click of a dropdown 😀
    const branchSwitchingEnabled = false;
    if (branchSwitchingEnabled && this.env === "demo") {
      // TODO need to remember this a little, because this component inits on every route change (because it's a fresh component in every page)
      this.activeBranchService.listBranches()
      .then(branches => {
        const showBranches = [];
        branches.forEach(branch => {
          if (branch.active) {
            this.activeBranch = branch.name;
          } else if (branch.requested) {
            this.requestedBranch = branch.name;
          } else {
            showBranches.push(branch.name);
          }
        });
        this.activeBranches = showBranches;
      });
    }
  }

  async ngAfterViewInit() {
    this.loadMenuItems(); // no need to await this
    this.translate.onLangChange.subscribe(this.loadMenuItems.bind(this));
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  activateBranch(): void {
    this.requestedBranch = this.activeBranch;
    this.activeBranchService.activateBranch(this.activeBranch);
  }

  getUpdateStatus(): string {
    if (this.requestedBranch && this.activeBranch !== this.requestedBranch) {
      return `Will update to '${this.requestedBranch}'`;
    } else {
      return undefined;
    }
  }

  private async loadMenuItems() {
    if (this.loggedIn) {
      this.menuItems = [
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_DASHBOARD")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_DASHBOARD_URL")),
          icon: 'dashboard',
        },
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SETTINGS")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SETTINGS_URL")),
          icon: 'settings',
        },
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_HELP")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_HELP_URL")),
          icon: 'help',
        }
      ];
    } else {
      this.menuItems = [
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_URL")),
          children: [
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_ALL")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_ALL_URL"))
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_BOOKKEEPING")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_BOOKKEEPING_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_ECOMMERCE")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_ECOMMERCE_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_CRM")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_CRM_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_POS")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_POS_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_PAYMENTS")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_SOFTWARE_INTEGRATIONS_PAYMENTS_URL")),
            },
          ],
        },
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_URL")),
          children: [
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_HOW_IT_WORKS")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_HOW_IT_WORKS_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_CUSTOMER_STORIES")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_CUSTOMER_STORIES_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_ABOUT_US")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_ABOUT_US_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_BLOG")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_BLOG_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_PARTNER")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_ABOUT_PARTNER_URL")),
            },
            // {
            //   label: 'Vacatures',
            //   href: '/over-combidesk/vacatures',
            // },
          ],
        },
        {
          label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE")),
          href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_URL")),
          children: [
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_SUPPORT")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_SUPPORT_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_KNOWLEDGEBASE")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_KNOWLEDGEBASE_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_INSTALL_SERVICE")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_INSTALL_SERVICE_URL")),
            },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_ADVISORS")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_ADVISORS_URL")),
            },
            // {
            //   label: 'Beveel ons aan',
            //   href: '/klantenservice/beveel-ons-aan',
            // },
            {
              label: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_CONTACT")),
              href: await firstValueFrom(this.translate.get("HEADER_MENU_ITEM_CUSTOMER_SERVICE_CONTACT_URL")),
            }
          ]
        }
      ];
    }
  }
}
