import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "../pages/home/home.component";
import { OAuthResultComponent } from "../pages/oauth-result/oauth-result.component";
import { ShopifyOAuthResultComponent } from "../pages/shopify-oauth-result/shopify-oauth-result.component";
import { AutoLoginComponent } from "../pages/auto-login/auto-login.component";
import { CheckLoginGuard } from "./check-login.guard";
import { EcwidOauthResultComponent } from "../pages/ecwid-oauth-result/ecwid-oauth-result.component";

const routes: Routes = [
  {path: '', loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule), data: {title: 'DEFAULT_PAGE_TITLE'}},
  {path: 'home', loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule), data: {title: 'DEFAULT_PAGE_TITLE'}},

  {path: 'contact', loadComponent: () => import('../pages/customer-service/contact/contact.component').then(m => m.ContactComponent)},
  {path: 'customerservice/contact', loadComponent: () => import('../pages/customer-service/contact/contact.component').then(m => m.ContactComponent), data: {altroutes: {nl: 'klantenservice/contact'}}},
  {path: 'klantenservice/contact', loadComponent: () => import('../pages/customer-service/contact/contact.component').then(m => m.ContactComponent), data: {altroutes: {en: 'customerservice/contact'}}},

  {path: 'customerservice/installation-support', loadComponent: () => import('../pages/customer-service/installation-support/installation-support.component').then(m => m.InstallationSupportComponent), data: {altroutes: {nl: 'klantenservice/hulp-bij-installatie'}}},
  {path: 'klantenservice/hulp-bij-installatie', loadComponent: () => import('../pages/customer-service/installation-support/installation-support.component').then(m => m.InstallationSupportComponent), data: {altroutes: {en: 'customerservice/installation-support'}}},

  {path: 'customerservice/advisors', loadComponent: () => import('../pages/customer-service/advisors/advisors.component').then(m => m.AdvisorsComponent), data: {altroutes: {nl: 'klantenservice/adviseurs'}}},
  {path: 'klantenservice/adviseurs', loadComponent: () => import('../pages/customer-service/advisors/advisors.component').then(m => m.AdvisorsComponent), data: {altroutes: {en: 'customerservice/advisors'}}},

  {path: 'customerservice/advisors/:slug', loadComponent: () => import("../pages/customer-service/advisors/advisor.component").then(m => m.AdvisorComponent)},
  {path: 'klantenservice/adviseurs/:slug', loadComponent: () => import("../pages/customer-service/advisors/advisor.component").then(m => m.AdvisorComponent)},

  {path: 'customerservice', loadComponent: () => import('../pages/customer-service/customer-service.component').then(m => m.CustomerServiceComponent), data: {altroutes: {nl: 'klantenservice'}}},
  {path: 'klantenservice', loadComponent: () => import('../pages/customer-service/customer-service.component').then(m => m.CustomerServiceComponent), data: {altroutes: {en: 'customerservice'}}},

  {path: 'terms', loadComponent: () => import('../pages/terms/terms.component').then(m => m.TermsComponent)},
  {path: 'privacy', loadComponent: () => import('../pages/privacy/privacy.component').then(m => m.PrivacyComponent)},
  {path: 'maintenance', loadComponent: () => import('../pages/maintenance/maintenance.component').then(m => m.MaintenanceComponent)},

  {path: 'over-combidesk', loadComponent: () => import("../pages/about-combidesk/about-combidesk.component").then(m => m.AboutCombideskComponent), data: {altroutes: {en: 'about-combidesk'}}},
  {path: 'over-combidesk/over-ons', loadComponent: () => import('../pages/about-us/about-us.component').then(m => m.AboutUsComponent), data: {altroutes: {en: 'about-combidesk/about-us'}}},
  {path: 'over-combidesk/partner-worden', loadComponent: () => import('../pages/partner-program/partner-program.component').then(m => m.PartnerProgramComponent), data: {altroutes: {en: 'about-combidesk/partner-program'}}},
  {path: 'over-combidesk/partner-worden/affiliate-partner-worden', loadChildren: () => import('../pages/become-affiliate/become-affiliate.module').then(m => m.BecomeAffiliateModule), data: {altroutes: {en: 'about-combidesk/partner-program/become-affiliate-partner'}}},
  {path: 'over-combidesk/partner-worden/adviseur-worden', loadComponent: () => import('../pages/become-advisor/become-advisor.component').then(m => m.BecomeAdvisorComponent), data: {altroutes: {en: 'about-combidesk/partner-program/become-advisor'}}},
  {path: 'over-combidesk/partner-worden/software-partner-worden', loadComponent: () => import('../pages/become-software-partner/become-software-partner.component').then(m => m.BecomeSoftwarePartnerComponent), data: {altroutes: {en: 'about-combidesk/partner-program/become-software-partner'}}},
  {path: 'over-combidesk/klantverhalen', loadComponent: () => import("../pages/customer-stories/stories.component").then(m => m.StoriesComponent), data: {altroutes: {en: 'about-combidesk/customer-stories'}}},
  {path: 'over-combidesk/klantverhalen/:id/:slug', loadComponent: () => import("../pages/customer-stories/story.component").then(m => m.StoryComponent)},
  {path: 'over-combidesk/blog', loadComponent: () => import("../pages/blog/blog.component").then(m => m.BlogComponent), data: {altroutes: {en: 'about-combidesk/blog'}}},
  {path: 'over-combidesk/blog/artikel/:id/:slug', loadComponent: () => import("../pages/blog/blog-post.component").then(m => m.BlogPostComponent), data: {altroutes: {en: 'about-combidesk'}}},
  {path: 'over-combidesk/blog/onderwerp/:id/:slug', loadComponent: () => import("../pages/blog/blog-category.component").then(m => m.BlogCategoryComponent), data: {altroutes: {en: 'about-combidesk'}}},
  {path: 'over-combidesk/blog/auteur/:slug', loadComponent: () => import("../pages/blog/blog-author.component").then(m => m.BlogAuthorComponent), data: {altroutes: {en: 'about-combidesk'}}},

  {path: 'about-combidesk', loadComponent: () => import("../pages/about-combidesk/about-combidesk.component").then(m => m.AboutCombideskComponent), data: {altroutes: {nl: 'over-combidesk'}}},
  {path: 'about-combidesk/about-us', loadComponent: () => import('../pages/about-us/about-us.component').then(m => m.AboutUsComponent), data: {altroutes: {nl: 'over-combidesk/over-ons'}}},
  {path: 'about-combidesk/partner-program', loadComponent: () => import('../pages/partner-program/partner-program.component').then(m => m.PartnerProgramComponent), data: {altroutes: {nl: 'over-combidesk/partner-worden'}}},
  {path: 'about-combidesk/partner-program/become-software-partner', loadComponent: () => import('../pages/become-software-partner/become-software-partner.component').then(m => m.BecomeSoftwarePartnerComponent), data: {altroutes: {nl: 'over-combidesk/partner-worden/software-partner-worden'}}},
  {path: 'about-combidesk/partner-program/become-advisor', loadComponent: () => import('../pages/become-advisor/become-advisor.component').then(m => m.BecomeAdvisorComponent), data: {altroutes: {nl: 'over-combidesk/partner-worden/adviseur-worden'}}},
  {path: 'about-combidesk/partner-program/become-affiliate-partner', loadChildren: () => import('../pages/become-affiliate/become-affiliate.module').then(m => m.BecomeAffiliateModule), data: {altroutes: {nl: 'over-combidesk/partner-worden/affiliate-partner-worden'}}},
  {path: 'about-combidesk/customer-stories', loadComponent: () => import("../pages/customer-stories/stories.component").then(m => m.StoriesComponent), data: {altroutes: {nl: 'over-combidesk/klantverhalen'}}},
  {path: 'about-combidesk/customer-stories/:id/:slug', loadComponent: () => import("../pages/customer-stories/story.component").then(m => m.StoryComponent)},
  {path: 'about-combidesk/blog', loadComponent: () => import("../pages/blog/blog.component").then(m => m.BlogComponent), data: {altroutes: {nl: 'over-combidesk/blog'}}},
  {path: 'about-combidesk/blog/post/:id/:slug', loadComponent: () => import("../pages/blog/blog-post.component").then(m => m.BlogPostComponent)},
  {path: 'about-combidesk/blog/category/:id/:slug', loadComponent: () => import("../pages/blog/blog-category.component").then(m => m.BlogCategoryComponent)},
  {path: 'about-combidesk/blog/author/:slug', loadComponent: () => import("../pages/blog/blog-author.component").then(m => m.BlogAuthorComponent)},

  // 'pillar' pages
  {path: 'info/webshop-beginnen', loadComponent: () => import("../pages/pillar/webshop/webshop-beginnen.component").then(m => m.WebshopBeginnenComponent), data: {altroutes: {en: 'info/start-webshop'}}},
  {path: 'info/start-webshop', loadComponent: () => import("../pages/pillar/webshop/webshop-beginnen.component").then(m => m.WebshopBeginnenComponent), data: {altroutes: {nl: 'info/webshop-beginnen'}}},
  {path: 'info/boekhouden-voor-webshops', loadComponent: () => import("../pages/pillar/boekhouden/boekhouden-voor-webshops.component").then(m => m.BoekhoudenVoorWebshopsComponent), data: {altroutes: {en: 'info/bookkeeping-for-webshops'}}},
  {path: 'info/bookkeeping-for-webshops', loadComponent: () => import("../pages/pillar/boekhouden/boekhouden-voor-webshops.component").then(m => m.BoekhoudenVoorWebshopsComponent), data: {altroutes: {nl: 'info/boekhouden-voor-webshops'}}},

  {path: 'recommend', loadChildren: () => import('../pages/become-affiliate/become-affiliate.module').then(m => m.BecomeAffiliateModule)},

  {path: 'oauthresult', component: OAuthResultComponent},
  {path: 'shopifyoauthresult', component: ShopifyOAuthResultComponent},
  {path: 'shopifyoauthresult/:package', component: ShopifyOAuthResultComponent},
  {path: 'shopyoauthresult', component: ShopifyOAuthResultComponent},
  {path: 'shopyoauthresult/:package', component: ShopifyOAuthResultComponent},
  {path: 'ecwid/:package', component: EcwidOauthResultComponent},

  {path: 'autologin/:token', component: AutoLoginComponent},

  // some 3rd OAuth providers have /combi in their path, so let's redirect to the correct route here (doesn't work if redirecting to /oauthredirect.html first)
  {path: 'combi/oauthredirect.html', redirectTo: 'oauthresult'},
  // Redirect oude /combi URLs naar root
  {path: 'combi', redirectTo: ''},

  {path: 'apps', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule)},
  {path: 'softwarekoppelingen', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule), data: {altroutes: {en: 'softwareintegrations'}}},
  {path: 'softwareintegrations', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule), data: {altroutes: {nl: 'softwarekoppelingen'}}},
  {path: 'softwarekoppelingen/:category', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule)},
  {path: 'softwareintegrations/:category', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule)},

  {path: 'appstore/:apiname', redirectTo: 'appstore/:apiname/', pathMatch: 'full'},
  {path: 'appstore/:apiname/:apiinternalkey', loadChildren: () => import('../pages/appstore/appstore.module').then(m => m.AppstoreModule)},
  {path: 'integrations', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule), data: {altroutes: {nl: 'koppelingen'}}},
  {path: 'integrations/:apiname', redirectTo: 'integrations/:apiname/', pathMatch: 'full'},
  {path: 'integrations/:apiname/:apiinternalkey', loadChildren: () => import('../pages/appstore/appstore.module').then(m => m.AppstoreModule)},
  {path: 'koppelingen', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule), data: {altroutes: {en: 'integrations'}}},
  {path: 'koppelingen/:apiname', redirectTo: 'koppelingen/:apiname/', pathMatch: 'full'},
  {path: 'koppelingen/:apiname/:apiinternalkey', loadChildren: () => import('../pages/appstore/appstore.module').then(m => m.AppstoreModule)},

  {path: 'combipackagedetails/:packageinternalkey', loadChildren: () => import('../pages/authenticated/combipackagedetails/combipackagedetails.module').then(m => m.CombiPackageDetailsModule)},
  {path: 'integration/:packageinternalkey', loadChildren: () => import('../pages/authenticated/combipackagedetails/combipackagedetails.module').then(m => m.CombiPackageDetailsModule)},
  {path: 'koppeling/:packageinternalkey', loadChildren: () => import('../pages/authenticated/combipackagedetails/combipackagedetails.module').then(m => m.CombiPackageDetailsModule)},

  {path: 'combipackageconfig', loadChildren: () => import('../pages/authenticated/combipackageconfig/combi-package-config.module').then(m => m.CombiPackageConfigModule)},
  // {path: 'combipackageinternal/:packageinternalkey', loadChildren: () => import('../pages/authenticated/combipackage/combipackage.module').then(m => m.CombipackageconfigModule)},

  {path: 'combipackage', loadChildren: () => import('../pages/authenticated/combipackage/combi-package.module').then(m => m.CombiPackageModule)},

  // Rewrite Simplicate (and maybe others) deeplinks
  {path: 'combipackageinstall', redirectTo: 'combipackage'},

  {path: 'teamleader/:packageinternalkey', loadChildren: () => import('../pages/teamleader/teamleader.module').then(m => m.TeamleaderModule)},
  {path: 'external/afas/payment', loadChildren: () => import('../pages/afas-payment/afas-payment.module').then(m => m.AfasPaymentModule)},
  {path: 'external/afas/paymentlink', loadChildren: () => import('../pages/afas-payment-link/afas-payment-link.module').then(m => m.AfasPaymentLinkModule)},
  {path: 'oauthmagento2request/:packageinternalkey', loadChildren: () => import('../pages/oauth-magento2-request/oauth-magento2-request.module').then(m => m.OauthMagento2RequestModule)},
  {path: 'oauthshopifysdkrequest/:packageinternalkey', loadChildren: () => import('../pages/shopify/oauth-shopify-sdkrequest/oauth-shopify-sdkrequest.module').then(m => m.OauthShopifySdkrequestModule)},
  {path: 'shopifysdkpaymentconfirmation', loadChildren: () => import('../pages/shopify/shopify-payment-confirmation/shopify-payment-confirmation.module').then(m => m.ShopifyPaymentConfirmationModule)},
  {path: 'shopifydashboard/:packageinternalkey', loadChildren: () => import('../pages/shopify/shopify-dashboard/shopify-dashboard.module').then(m => m.ShopifyDashboardModule)},
  {path: 'shopifyinstallationsupport', loadComponent: () => import('../pages/shopify/shopify-installation-support/shopify-installation-support.component').then(m => m.ShopifyInstallationSupportComponent)},

  {path: 'accountsettings', loadChildren: () => import('../pages/authenticated/accountsettings/accountsettings.module').then(m => m.AccountSettingsModule), canActivate: [CheckLoginGuard]},
  {path: 'dashboard', loadChildren: () => import('../pages/authenticated/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [CheckLoginGuard]},
  {path: 'admin/clients', loadChildren: () => import('../pages/authenticated/admin/clients/clients.module').then(m => m.ClientsModule), canActivate: [CheckLoginGuard]},
  {path: 'login', loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule)},
  {path: 'category/:id/:name', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule)},
  {path: 'categorie/:id/:name', loadChildren: () => import('../pages/apps/apps.module').then(m => m.AppsModule)},

  {path: 'bizcuitrequest', loadChildren: () => import('../pages/bizcuit/bizcuit.module').then(m => m.BizcuitModule)},

  {path: 'chat', component: HomeComponent},
  {path: '404', loadComponent: () => import('../pages/not-found/not-found.component').then(m => m.NotFoundComponent)},
  {path: '**', redirectTo: '404'},
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled",
        // enableTracing: true,
        // initialNavigation: "enabled",
        // onSameUrlNavigation: "reload"
      }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
