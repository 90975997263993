import { Component, Inject, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MessageService } from "./modules/shared/message/message.service";
import { UserService } from "./services/user/user.service";
import { getUrlParamValue } from "./util/url.util";
import { TranslateService } from '@ngx-translate/core';
import { bootIntercom } from "./util/intercom.util";
import { DOCUMENT } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService,
              private userService: UserService,
              private router: Router,
              @Inject(DOCUMENT) private dom,
              private messageService: MessageService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta) {

    translate.setDefaultLang('en');

    let language = getUrlParamValue(window.location.search, "language");
    if (!language) {
      const locale = getUrlParamValue(window.location.search, "locale");
      // note that if an unknown locale is passed, we fall back to "en" (see "setDefaultLang" above)
      language = locale?.length > 1 ? locale.substring(0, 2) : undefined;
    }

    if (language) {
      userService.languageOverride = language;
      if (userService.getUser()) {
        userService.setUser(userService.getUser(), false);
      } else {
        translate.use(language);
      }
    } else if (userService.getUser()?.language) {
      if ("de" === userService.getUser().language) {
        // backend "supports" "de", but frontend doesn't, so fall back to "en"
        translate.use("en");
      } else {
        translate.use(userService.getUser().language);
      }
    } else {
      const domain = window.location.host;
      if (domain.startsWith("en.")) {
        translate.use("en");
      } else if (domain.startsWith("nl.")) {
        translate.use("nl");
      } else {
        translate.use(translate.getBrowserLang());
      }
    }

    // update the html lang attribute if it's not the default (en)
    if (translate.currentLang !== "en") {
      const htmlElement: HTMLLinkElement = this.dom.querySelector("html");
      htmlElement.setAttribute("lang", translate.currentLang);
    }

    // don't add a favicon for Bizcuit
    const head = this.dom.getElementsByTagName("head")[0];
    const favicon = this.dom.createElement('link') as HTMLLinkElement;
    favicon.setAttribute("rel", "shortcut icon");
    favicon.setAttribute("href", `./assets/img/favicon/${this.isBizcuit() ? 'empty' : 'combidesk'}-favicon.png`);
    head.appendChild(favicon);
  }

  async ngOnInit() {
    if (this.isBizcuit()) {
      // Bizcuit doesn't have a title (showing an invisible character, otherwise the domain is shown in the tab)
      this.titleService.setTitle("‎");
      this.metaService.updateTag({ name: "robots", content: "none" })
    } else {
      this.titleService.setTitle(await firstValueFrom(this.translate.get("DEFAULT_PAGE_TITLE")));
      this.metaService.updateTag({ name: "robots", content: "index, follow" });
      this.metaService.updateTag({
        name: "description",
        content: await firstValueFrom(this.translate.get("DEFAULT_PAGE_META_DESCRIPTION"))
      });
      this.metaService.updateTag({
        name: "keywords",
        content: await firstValueFrom(this.translate.get("DEFAULT_PAGE_META_KEYWORDS"))
      });

      this.router.events.subscribe(async (e) => {
        if (e instanceof NavigationEnd) {
          console.log("Navigated to " + e.url + (e.urlAfterRedirects !== e.url ? " (️redirected to " + e.urlAfterRedirects + ")" : ""));
          // clear any active WARN/ERROR, because those are prolly no longer relevant for the new route
          if (this.messageService.hasActiveStickyMessage()) {
            this.messageService.hideMessage();
          }
          const rt = this.getChild(this.activatedRoute);
          const titleKey = rt.snapshot.data["title"];
          // set the page title, based on what's configured in the routes
          if (titleKey) {
            const title = this.translate.instant(titleKey);
            this.titleService.setTitle(title);
            // note: we could also have a different description per route, but using the title for now
            this.metaService.updateTag({
              name: "description",
              content: title
            });
          }

          // set the SEO altroutes if provided
          const altroutes = rt.snapshot.data["altroutes"];
          this.updateHreflang("nl", "nl", altroutes?.nl, e.url);
          this.updateHreflang("en", null, altroutes?.en, e.url);
          this.updateHreflang("x-default", null, altroutes?.en, e.url);
          this.updateCanonical();
        }
      });
    }

    // Only booting Intercom on prod/uat by calling the util
    bootIntercom(this.translate.currentLang, this.userService?.getUser());

    this.rememberAffiliateRef();

    setTimeout(() => {
      const successMessageKey = getUrlParamValue(window.location.href, "successMessageKey");
      if (successMessageKey) {
        this.messageService.showSuccessMessage(this.translate.instant(successMessageKey));
      }
      const errorMessageKey = getUrlParamValue(window.location.href, "errorMessageKey");
      if (errorMessageKey) {
        this.messageService.showErrorMessage(this.translate.instant(errorMessageKey));
      }
    }, 500);
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private isBizcuit(): boolean {
    const host = window.location.host;
    return host.indexOf("hub.") > -1 || host.indexOf("bizcuit") > -1;
  }

  private updateHreflang(lang: string, subdomain?: string, altroute?: string, path?: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[hreflang='${lang}']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'alternate');
    element.setAttribute('hreflang', lang);
    const domain = window.location.host;
    let href = subdomain
        ? window.location.href.replace(domain, `${subdomain}.combidesk.com`)
        : window.location.href.replace(domain, `combidesk.com`);
    if (altroute) {
      href = href.replace(path, `/${altroute}`);
    }
    element.setAttribute('href', href);
  }

  // rel=“canonical href=“absolute URL van pagina”
  private updateCanonical() {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', window.location.href);
  }

  private rememberAffiliateRef() {
    const affRef = getUrlParamValue(window.location.href, "ref");
    if (affRef) {
      this.userService.rememberAffiliateRef(affRef);
    }
  }
}
