import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Button } from './Button';
import { TranslateDirective, TranslatePipe } from "@ngx-translate/core";
import { User } from "../model";
import { UserService } from "../services/user/user.service";
import { sleep } from "../util/sleep.util";
import { RouterModule } from "@angular/router";

@Component({
  selector: 'MenuButtons',
  imports: [Button, RouterModule, TranslatePipe, TranslateDirective],
  template: `
    @if (loggedIn && !user.temporaryUser) {
      <div
          class="
        {{ class }}
        flex-col items-center gap-3
        xs:flex-row">

        <div
            class="
          {{ class }}
          flex-col items-center gap-3
          xs:flex-row">
          @if (menuOpen) {
            <Button
                [color]="'white'"
                (click)="logout()"
                [outline]="true"
                [shadow]="false"
                [size]="'small'">
              <svg
                  class="mr-2 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
              </svg>
              {{ "HEADER_MENU_ITEM_LOGOUT" | translate }}
            </Button>
          }
          <Button
              class="hidden sm:block"
              [color]="'red'"
              [href]="'HEADER_MENU_ITEM_ADD_COMBI_URL' | translate"
              [shadow]="false"
              [size]="'small'">
            + {{ "HEADER_MENU_ITEM_ADD_COMBI" | translate }}
          </Button>
          </div>

        @if (!menuOpen) {
  
          <!-- listoptions-toggle with avatar -->
          @if (user.avatar) {
            <button (click)="toggleMenu()"
                    class="ml-3 shadow hover:shadow-lg duration-200 hover:scale-105 focus:scale-100 rounded-full outline-none focus:outline-none">
              <div class="h-[50px] w-[50px] overflow-hidden rounded-full border-white border-2">
                <img [src]="user.avatar" class="h-full w-full object-cover" alt="avatar">
              </div>
            </button>
          }
  
          <!-- listoptions-toggle without avatar -->
          @if (!user.avatar) {
            <div
                (click)="toggleMenu()"
                class="
            text-blue-500
            rounded-full
            h-10
            w-10
            2xs:h-12
            2xs:w-12
            bg-blue-200
            hover:bg-white
            center
            cursor-pointer
            group
            relative
          ">
              <svg
                  class="w-[18px] h-5"
                  viewBox="0 0 18 20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g
                    transform="translate(1 1)"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                  <path d="M16 18v-2c0-2.2091-1.7909-4-4-4H4c-2.2091 0-4 1.7909-4 4v2"/>
                  <circle cx="8" cy="4" r="4"/>
                </g>
              </svg>
            </div>
          }
  
          <!-- menu -->
          <ul [class.hidden]="!expanded" class="absolute text-gray-500 z-40 cursor-pointer top-20 right-4">
            @if (user.adminForUsers) {
              <li routerLink="/admin/clients" routerLinkActive="text-gray-800" translate="HEADER_MENU_ITEM_ADMIN_CLIENTS"
                  class="dropdown-menu-item rounded-t"></li>
            }
            <li routerLink="/dashboard" routerLinkActive="text-gray-800" translate="HEADER_MENU_ITEM_DASHBOARD"
                class="dropdown-menu-item lg:hidden" [class.rounded-t]="!user.adminForUsers"></li>
            <li routerLink="/accountsettings" routerLinkActive="text-gray-800" translate="HEADER_MENU_ITEM_SETTINGS"
                class="dropdown-menu-item lg:hidden"></li>
            <li routerLink="/recommend" routerLinkActive="text-gray-800" translate="HEADER_MENU_ITEM_RECOMMEND"
                class="dropdown-menu-item lg:rounded-t"></li>
            <li routerLink="/klantenservice" routerLinkActive="text-gray-800" translate="HEADER_MENU_ITEM_HELP"
                class="dropdown-menu-item border-b border-gray-200 lg:hidden"></li>
            <li (click)="logout()" translate="HEADER_MENU_ITEM_LOGOUT" class="dropdown-menu-item rounded-b"></li>
          </ul>
        }

      </div>
    }

    @if (!loggedIn || user.temporaryUser) {
      <div
          class="
        {{ class }}
        flex-col items-center gap-3
        xs:flex-row">
        <Button
            [color]="'white'"
            [href]="'/login'"
            [outline]="true"
            [shadow]="false"
            [size]="'small'">
          <svg
              class="mr-2 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round">
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
          </svg>
          {{ "HEADER_MENU_ITEM_LOGIN" | translate }}
        </Button>
        <Button
            [class]="'xl:flex'"
            [color]="'red'"
            [href]="'/login?mode=REGISTER'"
            [shadow]="false"
            [size]="'small'">
          {{ "HEADER_MENU_ITEM_REGISTER" | translate }}
        </Button>
      </div>
    }
  `
})
export class MenuButtons implements OnInit {
  static TOGGLE_DELAY_MS = 50;

  @Input() class = '';
  @Input() menuOpen: boolean;

  expanded = false;
  loggedIn: boolean;
  user: User;

  constructor(private userService: UserService) {
  }

  // when clicking outside the element, we hide the selection
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.expanded = false;
  }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.loggedIn = this.user?.loggedIn;
  }

  async toggleMenu() {
    await sleep(MenuButtons.TOGGLE_DELAY_MS);
    this.expanded = !this.expanded;
  }

  logout(): void {
    this.expanded = false;
    this.userService.logout();
  }
}
